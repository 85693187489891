import Drawer from '@/components/basic/drawer';
import SharePanel from './share-panel-v2';
import React, {useEffect, useState} from 'react';
import {DrawerRef} from '@/components/basic/drawer/drawer';
import globalStore from '@/services/global.state';
import {shareToPlatform} from '@/common-pages/hooks/share.hooks';

export type ShareInfo = {
  // link 链接分享，目前这里也只有链接分享，可以不需要传type
  type?: 'link';
  // 具体的链接
  info?: string;
  tip?: string;
};
const SharePanelProvider = () => {
  const panelRef = React.useRef<DrawerRef>(null);
  const [shareInfo, setShareInfo] = useState<ShareInfo>();
  // const {shareSuccess, shareFail} = useShareToast();
  useEffect(() => {
    const sub = globalStore.shareProviderSubject.subscribe(info => {
      setShareInfo(info);
      panelRef.current?.open();
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Drawer
      mode="bottom"
      zIndex={11000}
      ref={panelRef}
      contentBackgroundColor="transparent">
      <SharePanel
        hasReward={false}
        onClose={() => panelRef.current?.close()}
        onItemPress={platform => {
          panelRef.current?.close();
          shareToPlatform(platform, shareInfo?.info as string, shareInfo?.tip);
        }}
      />
    </Drawer>
  );
};

export default SharePanelProvider;
