import React from 'react';
import {useTranslation} from 'react-i18next';
import './push-box.css';
import theme from '@/style';
const PushBox = () => {
  const {i18n} = useTranslation();
  return (
    <div className="moe-main-class" style={{display: 'none'}}>
      <div className="moe-wrap">
        <div className="moe-content">
          <div className="row-center">
            <img
              src={require('@components/assets/imgs/push-bg.png')}
              alt="push-bg"
              className="push-bg"
            />
          </div>
          <div className="content-wrap">
            <div className="text-wrap">
              <p
                className="main-text"
                style={{
                  color: theme.fontColor.second,
                }}>
                {i18n.t('push.mainText')}
              </p>
              <p
                className="sec-text"
                style={{
                  color: theme.fontColor.accent,
                }}>
                {i18n.t('push.secText')}
              </p>
            </div>
            <div className="buttons">
              <button
                className="moe-allow-class btn-allow"
                style={{
                  backgroundColor: theme.basicColor.primary,
                }}>
                {i18n.t('push.allow')}
              </button>
              <button
                className="moe-block-class btn-block"
                style={{
                  color: theme.basicColor.primary,
                }}>
                {i18n.t('push.block')}
              </button>
            </div>
          </div>
        </div>
        <div className="row-center moe-block-class">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none">
            <path
              d="M29.2189 9.28934C30.8937 12.4973 31.3085 16.2157 30.3816 19.7138C29.4547 23.212 27.2532 26.2371 24.2096 28.1949C21.166 30.1527 17.5003 30.9016 13.9327 30.2945C10.3651 29.6874 7.1535 27.7682 4.92878 24.9139C2.70407 22.0596 1.62709 18.4766 1.90943 14.8687C2.19177 11.2609 3.81303 7.88899 6.45468 5.41554C9.09633 2.94208 12.5674 1.54583 16.186 1.50111C19.8046 1.45638 23.3092 2.76641 26.0112 5.17383"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <rect
              x="12.6572"
              y="21.8926"
              width="3"
              height="13.3333"
              rx="1.5"
              transform="rotate(-135 12.6572 21.8926)"
              fill="white"
            />
            <rect
              x="21.8496"
              y="19.7715"
              width="3"
              height="13.3333"
              rx="1.5"
              transform="rotate(135 21.8496 19.7715)"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PushBox;
